<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-4">
      <h4>
        සාමාජික අංකය :
        <router-link
          :to="`/aw-admin/approved-members/${$route.params.id}`"
          style="color: inherit"
        >{{$route.params.id}}</router-link>
      </h4>
    </header>

    <v-row no-gutters>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="ජාතිය" v-model="race"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="ආගම" v-model="religion"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="කුලය" v-model="cast"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="දිස්ත්‍රික්කය" v-model="district"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="විවාහක තත්වය" v-model="marriageStatus"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="අධ්‍යාපන මට්ටම" v-model="education"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="රැකියා අංශය" v-model="job"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="මාසික අදායම" v-model="monthlyIncome"></v-checkbox>
      </v-col>
      <v-col cols="6" lg="2" md="3" sm="4">
        <v-checkbox hide-details label="වයස" v-model="age"></v-checkbox>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row no-gutters>
      <v-col cols="12" md="4" sm="6">
        <v-row no-gutters>
          <v-col cols="6">
            <v-select
              :items="heights"
              @change="fetchMatchedProposals"
              background-color="#fafafa"
              class="mx-1 my-1"
              clearable
              dense
              flat
              hide-details
              item-text="value"
              label="උස(සිට)"
              solo
              v-model="heightFrom"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="heights"
              @change="fetchMatchedProposals"
              background-color="#fafafa"
              class="mx-1 my-1"
              clearable
              dense
              flat
              hide-details
              item-text="value"
              label="උස(දක්වා)"
              solo
              v-model="heightTo"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" sm="6">
        <v-select
          :items="['ඔව්', 'නැත', 'නොදනී']"
          @change="fetchMatchedProposals"
          background-color="#fafafa"
          class="mx-1 my-1"
          clearable
          dense
          flat
          hide-details
          label="පාප කේන්දර"
          solo
          v-model="papaKendara"
        ></v-select>
      </v-col>
       <v-col class="d-flex justify-end">
        <v-switch class="mt-2" dense hide-details inset v-model="showBirthDateControl">
          <template slot="label">
            <span class="body-2 mt-1">උපන් දිනය සමග යවන්න</span>
          </template>
        </v-switch>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-switch class="mt-2" dense hide-details inset v-model="showBirthTimeControl">
          <template slot="label">
            <span class="body-2 mt-1">උපන් වේලාව සමග යවන්න</span>
          </template>
        </v-switch>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-switch class="mt-2" dense hide-details inset v-model="showImageControl">
          <template slot="label">
            <span class="body-2 mt-1">ඡායාරූප සමග යවන්න</span>
          </template>
        </v-switch>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-switch class="mt-2" dense hide-details inset v-model="alreadySentSwitch">
          <template slot="label">
            <span class="body-2 mt-1">යැවීමට නියමිත සාමාජිකයන්</span>
          </template>
        </v-switch>
      </v-col>
    </v-row>
    <v-divider class="mb-0"></v-divider>

    <v-data-table
      v-model="selectedMembers"
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="computedMatchedProposals"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      @click:row="(member) => $router.push(`/aw-admin/approved-members/${member.id}`)"
      class="custom-shadow custom-radius mt-8"
      show-select
      

    >
      <template slot="top">
        <v-row justify="end" no-gutters>
          <v-toolbar class="custom-radius" color="#fafafa" dense flat>
            <v-toolbar-title class="subtitle-1">
              <v-btn @click="fetchMatchedProposals" color="secondary" icon>
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-toolbar-title>
            <v-divider class="mx-sm-4" inset vertical></v-divider>
            <span v-if="$vuetify.breakpoint.mdAndUp">ගැලප‌ෙන යෝජනා</span>
            <v-btn @click.stop="openFinderDetailsDialog($route.params.id)" color="info" icon small>
              <v-icon small>mdi-information</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <span
              class="caption font-italic font-weight-bold text--secondary"
            >තව දුරටත් යැවිය හැකි ලිපි ගණන : {{remainingRounds}}</span>
            <v-btn
              :disabled="!remainingRounds"
              @click.stop="printPreview = true"
              class="ml-4"
              color="accent"
              icon
              v-if="selectedMembers.length > 0"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-toolbar>
        </v-row>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:header.data-table-select="{ on, props}">
        <v-simple-checkbox
          color="purple"
          v-bind="props" 
          v-on="on"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :disabled="item.isAlreadySent"
          :ripple="false"
          :value="isSelected"
          @input="select($event)"
          color="accent"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.id="{item}">
        <span>{{item.id}}</span>
        <v-btn @click.stop="openFinderDetailsDialog(item.id)" color="info" icon small>
          <v-icon small>mdi-information</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.isPackageUser="{item}">
        <v-chip color="accent" small v-if="item.isPackageUser">පැක‌ේජ</v-chip>
        <v-chip color="secondary" small v-else>නොමිලේ සාමාජිකත්වය</v-chip>
      </template>
    </v-data-table>

    <!-- Print preview -->
    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="printPreview">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn @click="printPreview = false" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>මුද්‍රණ පෙරදසුන</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn @click.stop="printBtnClicked = true" dark text v-print="'#print'">මුද්‍රණය කරන්න</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div id="print">
          <v-row class="ma-1" no-gutters>
            <v-col>
              <p>ඔබගේ සාමාජික අංකය : {{$route.params.id}}</p>
            </v-col>
            <v-col>
              <p style="text-align: end">දිනය : {{new Date().toLocaleDateString('ja-JP')}}</p>
            </v-col>
          </v-row>

          <p class="text-center">ඔබගේ යෝජනාවට ගැළපී තිබෙන සාමාජිකයින්ගේ විස්තර</p>

          <v-row justify="center" no-gutters>
            <table class="print-table ma-1" style="width:100%; font-size: smaller">
              <tr class="print-table text-center">
                <th class="px-1 print-table">සාමාජික අංකය</th>
                <!-- <th class="px-1 print-table">ඡායාරූපය</th> -->
                <th class="px-1 print-table">දු.අංකය</th>
                <th class="px-1 print-table">වයස</th>
                <th class="px-1 print-table" style="width: 50px">උස</th>
                <th class="px-1 print-table">ජාතිය</th>
                <th class="px-1 print-table">ආගම</th>
                <th class="px-1 print-table">කුළය</th>
                <th class="px-1 print-table">දිස්ත්‍රික්කය</th>
                <th class="px-1 print-table">විවාහක තත්වය</th>
                <th class="px-1 print-table">අධ්‍යාපන මට්ටම</th>
                <th class="px-1 print-table">රැකියා අංශය</th>
                <th class="px-1 print-table">රැකියා තත්වය</th>
                <th class="px-1 print-table">මාසික අදායම</th>
                <th class="px-1 print-table" v-if="showBirthDateControl">උපන් දිනය</th>
                <th class="px-1 print-table" v-if="showBirthTimeControl">උපන් වේලාව</th>
                <th class="px-1 print-table">වත්කම්/දායාද</th>
              </tr>
              <tr :key="member.id" class="print-table" v-for="member in selectedMembers">
                <td class="px-1 print-table">{{ member.id }}</td>
                <!-- <td class="px-1 print-table">
                  <div class="py-1" style="display: flex; justify-content:center;">
                    <div v-if="member.profilePicture && showImage">
                      <img :src="baseUrl + member.imageUrl" alt="photo" height="60px" width="60px" />
                    </div>
                  </div>
                </td>-->
                <td class="px-1 print-table">
                  <div v-if="showPhoneNumber">{{ member.mobileNumber }}</div>
                  <div v-else>xxxxxxxxxx</div>
                </td>
                <td class="px-1 print-table">{{ member.age }}</td>
                <td class="px-1 print-table">{{ member.height }}</td>
                <td class="px-1 print-table">{{ member.race }}</td>
                <td class="px-1 print-table">{{ member.religion }}</td>
                <td class="px-1 print-table">{{ member.cast }}</td>
                <td class="px-1 print-table">{{ member.district }}</td>
                <td class="px-1 print-table">{{ member.marriageStatus }}</td>
                <td class="px-1 print-table">{{ member.educationLevel }}</td>
                <td class="px-1 print-table">{{ member.job }}</td>
                <td class="px-1 print-table">{{ member.designation }}</td>
                <td class="px-1 print-table" style="text-align: end">{{ member.incomeValue }}</td>
                <td class="px-1 print-table" v-if="showBirthDateControl" style="text-align: end">{{ member.dob }}</td>
                <td class="px-1 print-table" v-if="showBirthTimeControl" style="text-align: end">{{ member.birthTime }}</td>
                <td class="px-1 print-table">{{ member.assetsDetails }}</td>
              </tr>
            </table>
          </v-row>

          <v-row class="mt-3" no-gutters>
            <v-col
              :key="member.id"
              cols="2"
              lg="2"
              style="display:flex; justify-content: center; font-size: smaller"
              v-for="member in selectedMembers"
            >
              <div class="photo-container" v-if="showImageControl">
                <img
                  :src="baseUrl + member.imageUrl"
                  alt="photo"
                  class="mx-1 photo"
                  height="144"
                  v-if="member.profilePicture && showImageControl"
                  width="144"
                />
                <img
                  alt="photo"
                  class="mx-1 photo"
                  height="144"
                  src="../../assets/images/not_found.png"
                  v-else
                  width="144"
                />
                <p class="text-center">{{ member.id }}</p>
              </div>
            </v-col>
          </v-row>

          <div class="ml-1" v-if="!showImageControl">
            <p style="font-size: smaller">ඡායාරූප :</p>
          </div>

          <p class="font-italic mx-1 mt-2" style="font-size: 0.6em">
            සැ.යු: එක් එක් සාමාජිකයින් ආයතනය වෙත ලබාදුන් ඔවුන්ගේ තොරතුරු මත පදනම්ව පමණක් ඉහත ගැලපීම් ඔබ වෙත එවා ඇති බව
            කරුණාවෙන් සලකන්න. ඔබට කටයුත්තක් තීන්දු වූ විගස ආයතනය අමතා ඔබගේ යෝජනාව ඉවත්කර ගැනීමට කාරුණික වන්න.
          </p>
        </div>
      </v-card>
    </v-dialog>

    <!-- Finder details dialog -->
    <v-dialog v-model="findeDetailsDialog" width="480">
      <v-card v-if="finder">
        <v-card-title>ස‌ොයන්නාග‌ේ විස්තර : {{memberId}}</v-card-title>
        <v-divider class="my-0"></v-divider>
        <v-card-text class="font-weight-bold">
          <v-row class="finder-row odd">
            <v-col cols="6" md="4">ජාතිය</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(race, i) in finder.finderRace">{{race.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row even">
            <v-col cols="6" md="4">ආගම</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(religion, i) in finder.finderReligion">{{religion.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row odd">
            <v-col cols="6" md="4">කුලය</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(cast, i) in finder.finderCast">{{cast.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row even">
            <v-col cols="6" md="4">දිස්ත්‍රික්කය</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(district, i) in finder.finderDistrict">{{district.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row odd">
            <v-col cols="6" md="4">විවාහක තත්වය</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(status, i) in finder.finderMarriageStatus">{{status.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row even">
            <v-col cols="6" md="4">අධ්‍යාපන මට්ටම</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(education, i) in finder.finderEducation">{{education.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row odd">
            <v-col cols="6" md="4">රැකියා අංශය</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(job, i) in finder.finderJob">{{job.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row even">
            <v-col cols="6" md="4">මාසික අදායම</v-col>
            <v-col cols="6" md="8">
              <span :key="i" v-for="(income, i) in finder.finderMonthlyIncome">{{income.value}},</span>
            </v-col>
          </v-row>
          <v-row class="finder-row odd">
            <v-col cols="6" md="4">වයස</v-col>
            <v-col cols="6" md="8">
              <span>{{finder.finderAge.ageFrom}} - {{finder.finderAge.ageTo}}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { CHANGE_LOADING_STATE, SHOW_ALERT } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";
import ConfirmDialog from "../../components/ConfirmDialog"

export default {
  name: 'matchedProposals',

  components: {
    ConfirmDialog
  },

  data: () => ({
    baseUrl: process.env.VUE_APP_BASE_URL,
    headers: [
      { text: "සාමාජික අංකය", value: "id", sortable: false },
      { text: "පැක‌ේජ වර්ගය", value: "isPackageUser", sortable: false },
      { text: "ලියාපදිංචි වූ දිනය", value: "created_at", align: 'end' }
    ],
    matchedProposals: [],
    selectedMembers: [],
    singleSelect: false,
    options: {},
    total: 0,
    loading: false,
    manualPackage: '',
    remainingRounds: 0,
    findeDetailsDialog: false,
    finder: '',
    alreadySentSwitch: false,
    // Matching parameters
    race: true,
    religion: true,
    cast: true,
    district: true,
    marriageStatus: true,
    education: true,
    job: true,
    monthlyIncome: true,
    age: true,
    printPreview: false,
    printBtnClicked: false,
    memberId: '',
    // Filters
    heights: [],
    papaKendara: '',
    heightFrom: '',
    heightTo: '',
    showImageControl: false,
    showBirthTimeControl: false,
    showBirthDateControl: false,
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    this.fetchQueryData();
    await this.fetchMatchedProposals();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  computed: {
    showImage: function () {
      if (this.manualPackage && this.manualPackage.photo) {
        return true;
      }
      
      return false;
    },
    showPhoneNumber: function () {
      if (this.manualPackage && this.manualPackage.phoneNumber) {
        return true;
      }
      return false;
    },
    computedMatchedProposals: function () {
      if (this.alreadySentSwitch) {
        return this.matchedProposals.filter(p => !p.isAlreadySent);
      } else {
        return this.matchedProposals;
      }
    }
  },

  watch: {
    options: {
      handler: function () {
        this.fetchMatchedProposals();
      },
      deep: true,
    },
    printPreview: function (value) {
      if (!value) {
        this.saveList();
      }
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch matched proposals
    fetchMatchedProposals: async function () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const order = sortDesc[0] ? 'desc' : 'asc';
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};
      const parameters = {
        race: this.race, religion: this.religion, cast: this.cast, district: this.district,
        marriageStatus: this.marriageStatus, education: this.education, job: this.job,
        monthlyIncome: this.monthlyIncome, age: this.age, heightFrom: this.heightFrom, heightTo: this.heightTo,
        papaKendara: this.papaKendara
      };

      try {
        const url = "customers/find-matching-proposals";
        const params = { params: { customer: this.$route.params.id, ...parameters, ...pageOptions, ...sortOptions } };
        const response = await ApiService.get(url, params);
        this.matchedProposals = response.data.data;
        this.total = response.data.meta.total;
        this.manualPackage = response.data.manualPackage;
        this.remainingRounds = response.data.remainingRounds;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Fetch data for query
    fetchQueryData: async function () {
      try {
        const url = "metadata";
        const response = await ApiService.get(url);
        this.heights = response.data.heights;
      } catch (error) {
        console.log(error);
      }
    },

    // Open finder details dialog
    openFinderDetailsDialog: async function (id) {
      this.loading = true;
      try {
        const url = `customers/finder-details/${id}`;
        const response = await ApiService.get(url);
        this.finder = response.data;
        this.memberId = id;
        this.findeDetailsDialog = true;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Save downloaded proposal list
    saveList: async function () {
      if (this.printBtnClicked && await this.$refs.confirm.open('යවන ලද යෝජනා ලැයිස්තුවට එක් කරන්න', 'යවන ලද යෝජනා ලැයිස්තුවට මෙම ලැයිස්තුව එක් කිරීමට අවශ්‍යද?',
        { color: 'info', persistent: true, width: 550 })) {
        this.loading = true;
        const receivers = this.selectedMembers.map(e => e.id);
        try {
          const url = "downloaded-proposals/create";
          const data = { customer: this.$route.params.id, receivers: receivers };
          const response = await ApiService.post(url, data);
          this.remainingRounds = response.data.remainingRounds;
          this.selectedMembers.map(e => e.isAlreadySent = true);
          this.selectedMembers = [];
          this.showAlert({ message: "යවන ලද ලැයිස්තුවට එකතු කරන ලදි", type: "success" });
        } catch (error) {
          this.showAlert({ message: "දෝෂයක් ඇතිවිය. කරුණාකර පසුව නැවත උත්සාහ කරන්න", type: "error" });
        }
        this.loading = false;
      }
      this.printBtnClicked = false;
    },

    selectAll(event) {
      if (event.status) {
        alert('selected all')
      } else {
        alert('deselected all')
      }
    }
  }
}
</script>

<style scoped>
@media print {
  @page {
    size: landscape;
  }
  table {
    page-break-after: auto;
  }
  tr,
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  .photo-container {
    page-break-after: auto;
  }
  .photo {
    page-break-inside: avoid;
    page-break-after: auto;
  }
}
.print-table {
  border: 1px solid black;
  border-collapse: collapse;
}
.finder-row {
  border-radius: 4px;
  margin: 5px 0px 5px 0px;
}
.odd {
  background-color: #eeeeee;
}
.even {
  background-color: #fafafa;
}
.photo {
  border: 1px solid grey !important;
  object-fit: cover !important;
}
</style>